.btnChooseFile {
  background: #2574DB;
  color: white;
  padding: 10px 30px;
  letter-spacing: 1px;
  font-size: 12px;
}

.descriptionUpload {
  color: #828282;
  font-size: 12px;
}