body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  height: 100%;

  #root {
    min-height: 100vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.btn-submit-form {
  background: #2D9CDB;
  border: 0;
  color: white;
  padding: 12px 18px
}

.text-menu {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #373F41;
}

.text-menu-active {
  color: #3C64B1 !important;
}

.btn-run {
  border: 1px solid #3C64B1;
  color: #3C64B1;
  padding: 10px 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
}

.font-16px {
  font-size: 16px;
}

.border-1px-primary {
  border: 1px solid #3C64B1;
}

video {
  outline: none;
}

button {
  outline: none;
}

.color-text-primary {
  color: #3C64B1;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.w-95 {
  width: 95%;
}