.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #3C64B1 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: bold;
  padding: 16px 16px;
  padding-left: 40px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 9px;
}

.ant-upload-list-text .ant-upload-list-item-name, .ant-upload-list-picture .ant-upload-list-item-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
}

.ant-message .anticon {
  top: -3px;
}

.ant-dropdown-menu {
  padding: 10px 10px;
}

.ant-select-dropdown {
  min-width: 120px !important;
}