.section {
  min-height: 800px;
  width: 100%;
  position: relative;
  background-size: cover !important;
  background-position: center;

  .content {
    position: absolute;
    bottom: 0;
    min-height: 40%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .container {
      max-width: 635px;

      .title {
        letter-spacing: 0.2px;
        font-size: 44px;
        color: #F2F3D9;
        font-weight: bold;
        margin-top: 25px;
      }

      .description {
        font-size: 16px;
        color: #F2F3D9;
      }

      .buttonRedirect {
        border: 1px solid #56CCF2;
        color: #56CCF2;
        font-size: 14px;
        padding: 5px 15px;
        margin-bottom: 40px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .content {
      .container {
        .title {
          font-size: 35px;
        }

        .description {
          font-size: 14px;
        }
      }
    }
  }
}