.title {
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
}

.bgVerification {
  background-image: url('../../assets/imgs/bg_login.jfif');
  background-repeat: no-repeat;
  background-size: cover;
}

.description {
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #F9564F;
}

.itemForm {
  width: 100%;

  input {
    padding: 11px 16px;
  }

  .nameField {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 24px;
  }
  .description {
    font-size: 14px;
  }
}