.title {
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 0.3px;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 30px;
  }
}