.btnTryIt {
  border: 1px solid #3C64B1;
  color: #3C64B1;
  font-size: 14px;
  padding: 5px 15px;
  //margin-bottom: 40px;
}

.titleFeature {
  color: #373F41;
  font-weight: bold;
  font-size: 40px;
}

.description {
  color: #737B7D;
  letter-spacing: 0.3px;
  font-size: 16px;
}

.imageFeature {
  min-width: 95%;
  width: 95%;
  height: 580px;
  min-height: 580px;
  object-fit: cover;
}

.containerContent {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 600px) {
  .titleFeature {
    font-size: 30px;
  }
  .description {
    font-size: 14px;
  }
  .containerContent {
    position: relative;
  }
  .imageFeature {
    min-width: 100%;
    width: 100%;
    height: 200px;
    min-height: 200px;
  }
}